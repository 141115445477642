import React from "react"
import { Link } from "gatsby";
import Layout from "components/layout"
import SEO from "components/seo"
import { Tag } from "components";
import { allContext } from "utils/context-util";

const Search = () => {

	const [search, setSearch] = React.useState('')


	const storyCountDom = allContext.map(ctx => {

		const linkMap = ctx.linkMap;
		const conceptTags = Object.keys(linkMap) || [];
		const filteredConceptTags = search === '' ? conceptTags : conceptTags.filter(el => el.toLowerCase().includes(search.toLowerCase()));

		const techTagsVDOM = filteredConceptTags.map((tag, index) => (
			<Tag key={index} title={tag} linkMap={linkMap} type={"Concept"} />
		))

		if (filteredConceptTags.length > 0) {

			return (
				<div>
					<h4><Link to={"../" + ctx.path}>{ctx.title}</Link></h4>
					<div style={{ display: "flex", flexWrap: 'wrap' }}>
						{techTagsVDOM}
					</div>
				</div>
			)
		} else {
			return <></>
		}

	})


	const handleSearch = (e) => {
		debugger;
		setSearch(e.target.value);
	}


	return (
		<Layout isVisibleBack={true}>
			<SEO title="Search" />
			<div>
				<div>
					<span style={{ marginRight: 6 }}>Ara</span>
					<input value={search} onChange={handleSearch} style={{ width: 300 }} type='text' />
				</div>
				{storyCountDom}
			</div>


		</Layout>
	)

}

export default Search



